import { useSessionStore } from "@/stores/session";
import { useGetStartedStore } from "@/stores/getStarted";

/**
 * on successful login,
 * parse the profile list from response
 * extract token and key
 * then patch that to the session store
 *
 * finally. navigate to profiles/
 * if there are more than 1 profile.
 *
 * otherwise (only one profile), navigate to home,
 * selecting the lone profile as the current profile
 */
const authorize = (response) => {
  const { updateProfileColor } = useProfilesApi();
  const sessionStore = useSessionStore();

  const { token, key, user_profiles, househould_id } = response;

  const profiles = updateProfileColor(
    user_profiles.map((u) => {
      return {
        ...u,
        properties: u?.property,
        avatar: u?.property?.avatar,
      };
    })
  );
  sessionStore.$patch({
    token,
    key,
    househould_id: househould_id,
    profiles,
  });
};

const navigatePostAuth = async (query) => {
  const localePath = useLocalePath();
  const getStartedStore = useGetStartedStore();
  const sessionStore = useSessionStore();
  const navigateObj = {
    path: localePath("/profiles"),
    query: query,
  };
  getStartedStore.$reset();

  await useFmaSdk().forceInit({
    serialNumber: sessionStore.identifiers?.serialNumber,
    terminalId: sessionStore.identifiers?.terminalName,
  });

  navigateTo(navigateObj);
};
export default function () {
  return {
    authorize,
    navigatePostAuth,
  };
}
