<template>
  <div>
    <FormContainer>
      <template #title>
        <span
          class="text-body-medium text-center text-white opacity-50 ltr:tracking-[0.72px] rtl:tracking-normal"
        >
          {{ $t("Welcome back") }}
        </span>
      </template>
      <template #subtitle>
        <h1
          class="text-display-small md:text-headline-big text-white opacity-[0.87] text-center ltr:tracking-[2.67px] rtl:tracking-normal"
        >
          {{ $t("Sign In") }}
        </h1>
      </template>
      <form
        novalidate
        @submit.prevent="onSubmit"
        @invalid-submit.prevent="onInvalidSubmit"
        class="flex flex-col gap-y-6 justify-center"
      >
        <FormInput
          :label="t('Email')"
          :placeholder="t('Your email')"
          name="username"
        ></FormInput>
        <FormPassword
          :label="t('Password')"
          :placeholder="t('Your Password')"
          name="password"
        >
        </FormPassword>

        <button
          type="button"
          class="text-end text-body-medium text-white opacity-[0.87] tracking-[0.42px]"
          @click.prevent="onForgotClick"
        >
          {{ $t("Forgot your password?") }}
        </button>
        <ButtonsCta
          :is-loading="isSubmitting"
          :disabled="!form.meta.value.valid || isSubmitting"
          class="variant-primary"
        >
          {{ $t("Continue") }}
        </ButtonsCta>
      </form>
      <div class="flex flex-col text-center mt-4">
        <p
          class="text-body-medium text-white opacity-50 tracking-[0.42px] rtl:hidden"
        >
          {{ $t("agreeToAeNetwork") }}
          <NuxtLink
            :to="localePath('/terms')"
            target="_blank"
            class="text-white"
          >
            {{ $t("Terms and Conditions") }} </NuxtLink
          >,
          <NuxtLink
            :to="localePath('/privacy')"
            target="_blank"
            class="text-white"
          >
            {{ $t("Privacy Policy") }}
          </NuxtLink>
        </p>
        <p
          class="text-body-medium text-white opacity-50 tracking-[0.42px] hidden rtl:block"
          v-html="
            $t('agreeToAeNetwork', {
              term: `
            <a class='text-white opacity-[0.87]' href='${localePath(
              '/terms'
            )}' target='_blank'>
              ${$t('Terms and Conditions')}
            </a>
            `,
              policy: `
            <a class='text-white opacity-[0.87]' href='${localePath(
              '/privacy'
            )}' target='_blank'>
              ${$t('Privacy Policy')}
            </a>
            `,
            })
          "
        ></p>
      </div>
    </FormContainer>

    <div class="w-full flex justify-center">
      <AuthSso
        class="sm:w-[336px] xl:w-[32rem]"
        @on-successful-sso="handleSuccessfulSSO"
      ></AuthSso>
    </div>
  </div>
</template>

<script setup>
import { useGetStartedStore } from "@/stores/getStarted";
import { useSessionStore } from "@/stores/session";
import { RESPONSE_STATUS } from "@/constants";
import { useForm } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import useSigninAuth from "@/composables/useSigninAuth";

const { loginSuccess } = useADtvGTM();
const { onListen } = useKeyBoardEvent("enter");
const sessionStore = useSessionStore();
const getStartedStore = useGetStartedStore();
const isSubmitting = ref(false);
const { updateProfileColor } = useProfilesApi();
const { openToast } = useToast();
const localePath = useLocalePath();
const router = useRouter();
const { authorize, navigatePostAuth } = useSigninAuth();

const props = defineProps({
  standalone: Boolean,
  showTitle: Boolean,
});

useFormRules();

const { t, locale } = useI18n();

const schema = {
  username: "required|email",
  password: "required",
};

const form = useForm({
  validationSchema: schema,
});

onMounted(() => {
  if (sessionStore.isAuthenticated) {
    console.log("User already authenticated navigate to homepage");
    return navigateTo({
      path: localePath("/"),
    });
  }
});

localize({
  en: {
    fields: {
      username: {
        required: "Please enter a valid email address",
        email: "Please enter a valid email address",
      },
      password: {
        required: "Please enter your password",
      },
    },
  },
});

const onInvalidSubmit = () => {};

const onSubmit = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;

  const { values } = form;

  sessionStore.generateIdentifiers(values.username);

  const payload = {
    body: {
      language_code: locale.value,
      model_external_id: "PC",
      ...values,
    },
    headers: {
      ...sessionStore.identifierHeaders,
    },
  };

  await useFmaSdk().forceInit({
    serialNumber: sessionStore.identifiers?.serialNumber,
    terminalId: sessionStore.identifiers?.terminalName,
  });
  const { status, msg, code, response } = await usePostMethod(
    "/api/uc/user/login",
    payload
  );

  if (status == 200 && code == "SUCCESS") {
    await authorize(response);
    try {
      if (sessionStore.code) {
        await useTvLink().confirm(sessionStore.code);
      }
    } catch (error) {
      let errorMsg = error;
      if (error == "WRONG_TV_CODE") {
        errorMsg = t(
          "The code entered is either invalid or expired. Please try again."
        );
      }
      openToast({
        content: errorMsg,
        type: "error",
      });

      return navigateTo({
        path: localePath("/link-tv"),
        query: { retry: true },
        external: true,
        replace: true,
      });
    }
    loginSuccess("email");
    useFmaSdk().login(sessionStore.househould_id);
    await navigatePostAuth({ redirectTo: router.currentRoute.value.fullPath });
  } else {
    if (status == RESPONSE_STATUS.unActivated) {
      // sign in inactive email, should go to step 3 of 3 page, can resend email
      getStartedStore.$patch({
        createForm: {
          ...getStartedStore.createForm,
          ...values,
        },
      });
      getStartedStore.openModal("createCodeSent");
    } else {
      openToast({ content: msg, type: "error" });
    }
  }

  isSubmitting.value = false;
};

const onForgotClick = async () => {
  if (props.standalone) {
    navigateTo(localePath("/forgot-password"));
    return;
  }
  getStartedStore.step = "reset";
};

onListen(() => form.meta.value.valid && onSubmit());

const handleSuccessfulSSO = async (payload) => {
  try {
    sessionStore.generateIdentifiers(payload.username);

    const response = await useRequest().post("/api/uc/user/login", payload);
    await useFmaSdk().forceInit({
      serialNumber: sessionStore.identifiers?.serialNumber,
      terminalId: sessionStore.identifiers?.terminalName,
    });

    // If new account show onboarding screen (Fill Details )
    if (response?.status === 1202003) {
      return handleOnboarding(payload);
    }

    if (response?.response) {
      response?.response && authorize(response.response);
      try {
        if (sessionStore.code) {
          await useTvLink().confirm(sessionStore.code);
        }
      } catch (error) {
        let errorMsg = error;
        if (error == "WRONG_TV_CODE") {
          errorMsg = t(
            "The code entered is either invalid or expired. Please try again."
          );
        }
        openToast({
          content: errorMsg,
          type: "error",
        });

        return navigateTo({
          path: localePath("/link-tv"),
          query: { retry: true },
          external: true,
          replace: true,
        });
      }

      loginSuccess(payload?.token_type);
      useFmaSdk().login(sessionStore.househould_id);
      return await navigatePostAuth({
        redirectTo: router.currentRoute.value.fullPath,
      });
    }
  } catch (error) {
    console.log("error", error);
    if (error?.status === 1202003) {
      return handleOnboarding(payload);
    }
    // something went wrong.
  }
};

const handleOnboarding = async (payload) => {
  getStartedStore.$patch((state) => {
    state.createForm = {
      ...state.createForm,
      username: payload.username,
    };

    if (payload.token_type == "google") {
      state.createForm = {
        ...state.createForm,
        first_name: payload.given_name,
        last_name: payload.family_name,
      };
    }

    if (payload.token_type == "facebook") {
      const nameArr = payload.name.split(" ");
      const first_name = nameArr[0];
      const last_name = nameArr.slice(1).join(" ");

      state.createForm = {
        ...state.createForm,
        first_name,
        last_name,
      };
    }

    state.ssoResponse = {
      token: payload.token,
      tokenType: payload.token_type,
    };

    state.step = "createDetails";
    console.log("state createDetails", state);
  });
};
</script>

<script>
export default { name: "SignIn" };
</script>

<style lang="scss" scoped></style>
